<template>
	<div class="home">
		<div class="left" v-loading="loading1">
			<div class="tree">
				<el-tree :data="tree" :expand-on-click-node="false" class="filter-tree" node-key="typeName" :props="defaultProps" :default-expanded-keys="zhankai"
				 :highlight-current="true" @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{node,data}" style="width: 100%;height: 100%;">
						<span style="width: 100%;height: 100%;line-height: 32px;">{{data.typeName}}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">
			<div class="transformer">
				<div class='title' style=' padding-top:20px;background-color:#f3f3f3'>
					<el-form :inline="true" style="display:flex;flex-wrap:wrap" label-width="80px" ref='searchlist'>
						<el-form-item label="类型名称">
							<el-input v-model="labelName" clearable placeholder="请输入类型名称"></el-input>
						</el-form-item>
						<el-form-item label="备注">
							<el-input v-model="remarks" clearable placeholder="请输入备注"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="chaxun">查询</el-button>
						</el-form-item>
						<el-form-item>
							<el-button v-if="newbutton" type="primary" @click="deviceUpateDialog('add')"><i class="el-icon-plus"></i> 新增</el-button>
						</el-form-item>
						
					</el-form>
				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" stripe style="width: 100%;margin-top: 20px;">
						<el-table-column show-overflow-tooltip align="center" prop="type_name" label="类型名称">
						</el-table-column>
						<el-table-column show-overflow-tooltip align="center" prop="label_name" label="标签名称">
						</el-table-column>
						<el-table-column show-overflow-tooltip align="center" label="是否使用">
							<template slot-scope="scope">
								<span v-if="scope.row.enable == 0">是</span>
								<span v-else-if="scope.row.enable == 1">否</span>
							</template>
						</el-table-column>
						<el-table-column show-overflow-tooltip align="center" prop="sort" label="排序">
						</el-table-column>
						<el-table-column show-overflow-tooltip align="center" prop="remarks" label="备注">
						</el-table-column>
						<el-table-column show-overflow-tooltip align="center" width="200px" label="操作">
							<template slot-scope="scope">
								<el-button type="primary" @click="deviceUpateDialog('edit', scope.row)">编辑</el-button>
								<el-button type="danger" @click="deleteDevice(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
						 :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper"
						 :total="fenye.totalnum">
						</el-pagination>
					</div>
				</div>
				<el-dialog :close-on-click-modal='false' :title="updateType=='add'?'新增':'修改'" :visible.sync="dialogFormVisible" width="700px"
					@closed="colsedDialog('form')">
					<el-form :model="form" ref="form" class="formbox" :rules="rules" label-width="140px">
						<el-form-item label="类型名称">
							<span>{{leixingname}}</span>
						</el-form-item>
						<el-form-item label="标签名称" prop="labelName">
							<el-input v-model="form.labelName"></el-input>
						</el-form-item>
						<el-form-item label="是否使用" prop="enable">
							<el-select style="width:100% !important;" v-model="form.enable" placeholder="请选择">
								<el-option label="是" value="0">
								</el-option>
								<el-option label="否" value="1">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="排序" prop="sort">
							<el-input v-model="form.sort" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
						</el-form-item>
						<el-form-item label="备注" prop="remarks">
							<el-input type="textarea" :rows="2" v-model="form.remarks"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" :loading="submitLoading" @click="submitForm('form')">确 定</el-button>
							<el-button @click="resetForm('form')">取 消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		labelItemPage,
		labelTree,
		addLabelItem,
		editLabelItem,
		deleteLabelItem
	} from '@/api/tag'
	export default {
		data() {
			return {
				zhankai:[],
				rules: {
					labelName: [{
						required: true,
						message: '请输入标签名称',
						trigger: 'blur'
					}, ],
					
				},
				submitLoading:false,
				time: new Date(),
				updateType:'add',
				treeId: "",
				id:'',
				itemId:'',
				labelName:'',
				remarks: '',
				dialogFormVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 20, 30, 40],
					pagesize: 10,
					totalnum: 0
				},
				form: {
					labelId: '',
					id:'',
					labelName: '',
					sort: '',
					enable: '0',
					remarks:''
				},
				defaultProps: {
					children: 'labelItems',
					label: 'typeName'
				},
				treeSelectId: "",
				tree: [],
				newbutton: false,
				levelcode: '',
				regioncode: '',
				loading1: true,
				loading2: true,
				leixingname:''
			}
		},
		watch: {

		},
		mounted() {
			this.getTree();
			this.getList();
		},
		methods: {
			//打开编辑弹框
			deviceUpateDialog(type, row) {
				this.updateType = type;
				this.dialogFormVisible = true;
				this.form.id = '';
				if (type == 'edit') {
					this.form = {
						id: row.id,
						labelName: row.label_name,
						remarks: row.remarks,
						enable: row.enable + '',
						sort: row.sort,
					}
				}
			},
			colsedDialog(formName) {
				this.resetForm(formName);
			},
			//重置表单
			resetForm(formName) {
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					this.dialogFormVisible = false;
				}
			},
			// nodeClick() {
			// 	let node = this.$refs.tree.getCurrentNode()
			// 	this.$emit("treeClick", node.name, node.id)
			// },
			
			getTree() {
				var that = this;
				this.loading1 = true;
				labelTree({
					userid: this.GLOBAL.adminId()
				}).then((res) => {
					this.loading1 = false;
					if (res.result == 200) {
						console.log(res);

						var detail = res.detail;
						that.zhankai = ['全部'];
						for (var a = 0; a < detail.length; a++) {
							detail[a].ji = 2;
							detail[a].num = a;
							that.zhankai.push(detail[a].typeName);
							for (var i = 0; i < detail[a].labelItems.length; i++) {
								detail[a].labelItems[i].ji = 3;
								detail[a].labelItems[i].num = i;
								detail[a].labelItems[i].prevnum = a;
								detail[a].labelItems[i].fuid = detail[a].id;
								detail[a].labelItems[i].typeName = detail[a].labelItems[i].labelName;
							}
						}
						var list = [{
							typeName:'全部',
							ji:1,
							id:'0',
							labelItems:detail
						}]
						this.tree = list;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleClose: function(done) {
				var that = this;
				that.form.address = '';
				that.form.name = '';
				that.form.address1 = '';
				that.form.address2 = '';
				that.form.wuyename = '';

				that.form.phone = '';
				done();
			},

			//提交表单
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let params = {
							userid: this.GLOBAL.adminId(),
							labelId: this.updateType == 'add'?this.id:'',
							id:this.form.id,
							labelName: this.form.labelName,
							sort: this.form.sort,
							enable: this.form.enable,
							remarks: this.form.remarks,
						}
						this.submitLoading = true;
						if (this.updateType == 'add') {
							addLabelItem(params).then((res) => {
								this.submitLoading = false;
								if (res.result == 200) {
									this.$message.success('新增成功');
									this.getList();
									this.getTree();
									this.dialogFormVisible = false;
								} else {
									this.$message.error(res.description);
								}
							})
						} else {
							editLabelItem(params).then((res) => {
								this.submitLoading = false;
								if (res.result == 200) {
									this.$message.success('更新成功');
									this.getList();
									this.getTree();
									this.dialogFormVisible = false
								} else {
									this.$message.error(res.description);
								}
							})
						}
			
					} else {
						return false;
					}
				});
			},
			close() {
				var that = this;
				that.dialogTableVisible = false;
				that.form.name = '';
				that.form.wuyename = '';
				that.form.address = '';
				that.form.phone = '';
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			nodeClick(data) {
				console.log(data);
				var that = this;
				// that.tableData = [];
				that.currentPage = 1;
				that.id = '';
				that.itemId = '';
				that.labelName = '';
				that.remarks = '';
				if (data.ji == 1) {
					that.newbutton = false;
				} else if(data.ji == 2) {
					that.newbutton = true;
					that.id = data.id;
					that.leixingname = data.typeName;
				}else if(data.ji == 3){
					that.newbutton = true;
					that.id = data.fuid;
					that.itemId = data.id;
				}
				that.getList();
			},
			getList() {
				var that = this;
				// console.log(that.currentPage);
				that.loading2 = true;
				labelItemPage({
					userid: that.GLOBAL.adminId(),
					id: that.id,
					itemId:that.itemId,
					labelName:that.labelName,
					remarks:that.remarks,
					page:that.currentPage,
					pageSize:that.fenye.pagesize
				}).then((res) => {
					that.loading2 = false;
					if (res.result == 200) {
						console.log(res);
						this.tableData = res.detail.list;
						if(res.detail.list.length != 0){
							this.leixingname = res.detail.list[0].type_name;
						}
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			chaxun() {
				this.currentPage = 1;
				this.getList();
			},
			//删除设备
			deleteDevice(row) {
				this.$confirm('是否确认删除该标签?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteLabelItem({
						userid: this.GLOBAL.adminId(),
						id: row.id
					}).then((res) => {
						if (res.result == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getList();
							this.getTree();
						} else {
							this.$message.error(res.description);
						}
					})
			
				}).catch(() => {});
			}
		},

	}
</script>

<style lang="scss" scoped="scoped">
	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;
		border: solid 1px #d7dde4;
		box-shadow: 0 0 3px rgba($color: #d7dde4, $alpha: 0.3);

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& ::v-deep .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: "+";
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: "-";
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;

		}
	}

	.right>div {
		box-sizing: border-box;
		height: calc(100% - 32px);
		overflow-y: auto;
	}

	.xinzeng {
		width: calc(100% - 20px);
	}
</style>
